<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><font-awesome-icon icon="project-diagram" /> Projeto - Importar Planilha</h5>
                </div>
                <detalhar-projeto :projeto="projeto"></detalhar-projeto>
                <detalhe titulo="Selecione a planilha" size="130">
                    <FileUpload
                        :customUpload="true"
                        @uploader="arquivoSelecionado"
                        mode="basic"
                        chooseLabel="Selecionar Arquivo"
                        :auto="true"
                        v-if="!arquivo"
                    />
                    <div v-if="arquivo">
                        <strong>{{ nomeArquivo }}</strong>
                        <i class="pi pi-trash p-error icon-button p-ml-2" style="fontsize: 1.2rem" @click="limparArquivo()"></i>
                    </div>
                </detalhe>
                <erros-box :erros="erros"></erros-box>
                <div class="p-mt-4">
                    <btn-voltar :route="{ name: 'Projetos_Detalhar' }"></btn-voltar>
                    <Button
                        label="Processar planilha"
                        icon="pi pi-check-circle"
                        class="p-ml-2"
                        @click="confirmarProcessar()"
                        v-if="$temAcessoView('PROJETOS-04') && arquivo"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import DetalharProjeto from '../DetalharProjeto';
import PlanilhasServices from './services';

export default {
    components: {
        DetalharProjeto,
    },

    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        projeto: {
            type: Object,
        },
    },

    data() {
        return {
            erros: [],
            arquivo: null,
            nomeArquivo: null,
            arquivoAlterado: false,
        };
    },

    validations() {
        return {
            arquivo: { required },
        };
    },

    methods: {
        preencher() {},

        resetar() {
            this.arquivo = null;
            this.$nextTick(() => {
                this.v$.$reset();
            });
        },

        fechar() {
            this.$router.push({
                name: 'Projetos_Detalhar',
            });
        },

        confirmarProcessar() {
            this.$confirm.require({
                message: 'Tem certeza que deseja prosseguir?',
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.processar();
                },
            });
        },

        processar() {
            this.$store.dispatch('addRequest');
            let planilhaDto = {
                projetoId: this.$route.params.id,
                arquivo: this.arquivo,
            };
            PlanilhasServices.processar(planilhaDto).then((r) => {
                if (r && r.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Planilha',
                        detail: 'Processada com sucesso',
                        life: 3000,
                    });
                    this.resetar();
                    this.fechar();
                } else {
                    this.erros = r.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        arquivoSelecionado(event) {
            this.arquivo = event.files[0];
            this.nomeArquivo = this.arquivo.name;
        },

        limparArquivo() {
            this.arquivo = null;
            this.arquivoAlterado = true;
        },
    },

    watch: {},

    computed: {},
};
</script>

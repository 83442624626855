import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PROJETOS_PORT}${process.env.VUE_APP_API_PATH}`;
const urlBase = `${api}/projetos/planilhas`;

const formDataType = {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
};

export default {
    processar(planilha) {
        const formData = new FormData();
        formData.append('projetoId', planilha.projetoId);
        formData.append('arquivo', planilha.arquivo);
        return axiosJwt.post(`${urlBase}/processar`, formData, formDataType);
    }
};
